export function SaveButton(container, drawingCanvas) {
  var action = 'Save';
  var button = document.createElement('a');
  button.href = '#';
  button.text = action;
  button.addEventListener('click', handleSave, false);
  container.append(button);

  async function post(image, type, height, width, duration) {
    const response = await fetch('/drawing/new', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        image,
        type,
        height,
        width,
        duration
      })
    });
    if (!response.ok) {
      throw new Error('Error saving drawing');
    }
    return response.json();
  }

  var saving = false;

  function handleSave(evt) {
    evt.preventDefault();
    if (saving) {
      console.log('disabled multiple saves -- patience, my friend');
      return;
    }

    saving = true;
    button.text = 'Saving...';
    const {
      image,
      type,
      height,
      width,
      duration
    } = drawingCanvas.getImage();

    post(image, type, height, width, duration)
      .then((data) => {
        saving = false;
        console.log(data);
        if (data.status === 'ok') {
          location = `/drawing/${data.id}`;
        }
        else {
          flashMessage('Saved!', action);
        }
      })
      .catch((err) => {
        saving = false;
        flashMessage('Uh, error', action);
        console.error(err);
      })
    ;
  }

  function flashMessage(msg, orig, delay) {
    var original = orig || button.text;
    button.text = msg;
    setTimeout(() => {
      button.text = original;
    }, delay || 2000);
  }

  return {
  };
}
