/**
 * @param initialElapsedSeconds if supplied, then this initializes the timer
 * with this amount of already elapsed seconds
 * @param debug if true, then the timer outputs debug messages roughly every
 * second
 */
export function Timer(initialElapsedSeconds, debug) {
  var elapsedMs = (parseInt(initialElapsedSeconds) || 0) * 1000;
  var start = -1;

  if (debug) {
    setInterval(function () {
      if (start > 0) {
        var l = Math.floor((elapsedMs + (Date.now() - start)) / 1000);
        console.log(`Timer: ${l}s`);
      }
    }, 1000);
  }

  function _start() {
    start = Date.now();
  }

  function _stop() {
    if (start > 0) {
      elapsedMs += (Date.now() - start);
      start = -1;
    }
  }

  function _duration() {
    return Math.floor(elapsedMs / 1000);
  }

  return {
    start: _start,
    stop: _stop,
    duration: _duration
  };
}
