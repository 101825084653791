import { Timer } from './timer';

/**
 * @param container the HTMLElement into which to put a canvas
 * @param lineWidth the pixel width of the line to draw
 */
export function DrawingCanvas(container, lineWidth) {
  var canvas = document.createElement('canvas');
  var options = {
    capture: false,
    passive: false,
  };
  canvas.addEventListener("pointerdown", handleStart, options);
  canvas.addEventListener("pointerup", handleEnd, options);
  canvas.addEventListener("pointercancel", handleCancel, options);
  canvas.addEventListener("pointermove", handleMove, options);
  canvas.addEventListener("touchmove", preventTouch, options);
  container.append(canvas);
  canvas.width = window.innerWidth - canvas.offsetLeft;
  canvas.height = window.innerHeight - canvas.offsetTop - 10;

  // Used to calculate how much time is spent drawing.
  var timer = null;
  // Keep track of the last duration that was recorded so that it can be saved
  // along with the drawing, if it is saved multiple times before drawing any
  // more.
  var duration = -1;
  document.addEventListener('visibilitychange', () => {
    if (!timer) {
      return;
    }
    // Document just became hidden, so stop the timer
    if (document.hidden) {
      console.log('document went hidden');
      timer.stop();
    }
    else {
      console.log('document reappearred');
      timer.start();
    }
  });

  var context = canvas.getContext('2d');

  var drawingToEdit = document.querySelector('img#edit');
  if (drawingToEdit) {
    drawingToEdit.addEventListener('load', () => {
      context.drawImage(drawingToEdit, 0, 0);
    });
  }

  var ongoingTouches = {};

  var portraitRange =
    '(min-width: 768px) and (max-width: 1180px)';
  var landscapeRange =
    '(min-width: 768px) and (max-width: 1180px) and (orientation: landscape)';
  var isTabletQuery = matchMedia(`(${portraitRange}) or (${landscapeRange})`);
  var isTablet = isTabletQuery.matches;
  // Only respond to stylus events when on a mobile device.
  // This is helpful, because I often put my hand on the tablet and do not want
  // my wrist or knuckles resulting in drawing.
  function skipHandleTouch(evt) {
    return !(
      false
      || (isTablet && evt.pointerType === 'pen')
      || (!isTablet)
    );
  }

  /*
   * This is needed to prevent the iOS Scribble function from stealing events
   * from the stylus.
   * See: https://mikepk.com/2020/10/iOS-safari-scribble-bug/
   */
  function preventTouch(evt) {
    evt.preventDefault();
    return false;
  }

  function handleStart(evt) {
    evt.preventDefault();
    if (skipHandleTouch(evt)) {
      return;
    }
    // This is the very beginning of drawing, so initialize a new timer and
    // start timing.
    if (!timer) {
      // If this drawing is based off of another, then start the timer with that
      // drawing's duration, so that the time spent on this one is cumulative.
      if (drawingToEdit && drawingToEdit.dataset.duration) {
        timer = Timer(drawingToEdit.dataset.duration);
      }
      else {
        timer = Timer();
      }
      timer.start();
    }
    var touch = copyTouch(evt);
    ongoingTouches[evt.pointerId] = touch;
    // make a circle to capture points without drags
    context.beginPath();
    context.arc(touch.x, touch.y, lineWidth / 2, 0, 2 * Math.PI, false);
    context.fill();
  }

  function handleMove(evt) {
    evt.preventDefault();
    // buttons being 1 means there is pen contact or a prmary mouse button down.
    // If that is not the case, then abort and draw nothing.
    if (evt.buttons !== 1) {
      return;
    }
    if (skipHandleTouch(evt)) {
      return;
    }
    var idx = evt.pointerId;

    if (ongoingTouches[idx]) {
      var touch = copyTouch(evt);
      context.beginPath();
      context.moveTo(ongoingTouches[idx].x, ongoingTouches[idx].y);
      context.lineTo(touch.x, touch.y);
      context.lineWidth = lineWidth;
      context.stroke();

      ongoingTouches[idx] = touch;  // swap in the new touch record
    }
  }

  function handleEnd(evt) {
    evt.preventDefault();
    if (skipHandleTouch(evt)) {
      return;
    }
    var idx = evt.pointerId;

    if (ongoingTouches[idx]) {
      var touch = copyTouch(evt);
      context.beginPath();
      context.moveTo(ongoingTouches[idx].x, ongoingTouches[idx].y);
      context.lineTo(touch.x, touch.y);
      context.lineWidth = lineWidth;
      context.stroke();
      delete ongoingTouches[evt.pointerId];  // remove it; we're done
    }
  }

  function handleCancel(evt) {
    evt.preventDefault();
    delete ongoingTouches[evt.pointerId]; // remove it; we're done
  }

  function copyTouch(touch) {
    return {
      identifier: touch.pointerId,
      x: touch.offsetX,
      y: touch.offsetY,
    };
  }

  function getImage() {
    if (timer) {
      timer.stop();
      duration = timer.duration();
      // Reset the timer so that it starts up next time drawing starts.
      timer = null;
    }
    return {
      image: canvas.toDataURL('image/png'),
      type: 'png',
      height: canvas.height,
      width: canvas.width,
      duration: duration
    };
  }

  return {
    getImage
  };
}
