var cookieName = '_tz';

export function TimezoneSetter(document) {
  function init() {
    var locationData = Intl.DateTimeFormat().resolvedOptions();
    document.cookie = `${cookieName}=${locationData.timeZone}; Path=/; Secure`;
  }

  return {
    init
  };
}
