import './styles.css';

import { DrawingCanvas } from './drawing-canvas.js';
import { SaveButton } from './save-button.js';
import { TimezoneSetter } from './timezone-setter.js';

function initialize() {
  TimezoneSetter(document).init();
  if (document.querySelector('#canvas')) {
    var canvas = DrawingCanvas(document.querySelector('#canvas'), 2);
    SaveButton(document.querySelector('#save'), canvas);
  }
} 

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initialize);
}
else {
  initialize();
}
